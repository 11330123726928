import React from 'react';
import './Line.scss';

interface ILineProps {
    isRed : boolean;
}

const Line : React.FC<ILineProps> = props => {
    const redClassName = props.isRed ? 'red' : '';

    return (
        <svg viewBox="0 -1 507.93268 24.087193" className={`line ${redClassName}`}>
            <g transform="translate(148.16731,-156.34985)">
                <path className={`path`}
                d="m -148.16666,156.48214 49.275206,0.24204 c 10.665104,0.90566 20.760112,1.13758 38.805506,10.7654 14.538048,8.80916 25.391251,11.07553 44.563745,2.00286 19.1315929,-6.73929 34.073221,-20.34546 64.84275,-8.01146 9.622823,2.76497 14.55032,12.02053 35.050137,-0.25035 9.571289,-5.08457 14.585262,-4.56014 19.778296,-4.25609 l 115.92531,-0.13945 c 3.50185,0.75468 7.00369,2.07283 10.50553,4.89625 11.39169,7.60876 17.00929,5.59403 22.28188,3.0043 11.87319,-3.79423 14.85321,2.63867 20.279,6.25895 9.68938,8.35646 18.20993,10.3493 24.78545,1.75251 5.15139,-5.95576 10.69108,-16.6251 18.27613,-15.52221 l 43.56234,-0.25035"/>
            </g>
        </svg>
    );
}

export default Line;